import { gql } from "@apollo/client";

export const GET_CONTENT_PAGE = (params) => {
    const page = params?.page || "about";
    const language = params?.offset || "ru";

    return gql`
        query GetContentPage {
          contentmainpage(filters: {page: ${page}, languageText: ${language}}) {
            contentSection
            text
          }
        }
    `;
}